import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import Form from "../../components/Form/Form";
import {
  bulkUploadFormFixture,
  bulkUploadInputFixture,
  validateField,
} from "./utilities/fixtures";
import { getAllSPS, onChangeInputData } from "../../utilities/helpers";
import { postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import validate from "../../utilities/validation";
import { toast } from "react-toastify";

const BulkUpload = ({
  loading = false,
  openModal = false,
  centerNames = [],
  setOpenModal = () => {},
  selectedChildIds = [],
  setLoading = false,
  resetSelection = () => {},
}) => {
  const [formFixture, setFormFixture] = useState(
    bulkUploadFormFixture("education")
  );
  const [inputs, setInputs] = useState(bulkUploadInputFixture);
  const [sps, setSps] = useState([]);

  const handleInputChange = (e) => {
    onChangeInputData(e, inputs, setInputs);
  };

  const handleSubmit = async () => {
    if (!loading && handleValidation() && selectedChildIds.length) {
      let body = {
        bulk: selectedChildIds,
        ...inputs,
      };
      setLoading(true);
      const { success, message } = await postDataWithAuth({
        api_url: endpoints["bulkUpdate"],
        data: body,
      });
      setLoading(false);
      if (success) {
        if (message) toast.success(message);
        resetSelection();
        handleModalClose();
      } else {
        if (message) toast.error(message);
        else toast.error("Something went wrong!");
      }
    }
  };

  const handleValidation = () => {
    let validation = true;
    let fields = validateField(inputs.update_type);
    for (let i = 0; i < fields.length; i++) {
      let err = validate[fields[i]](inputs[fields[i]]);
      if (!!err) {
        toast.error(err);
        return false;
      }
    }
    return validation;
  };

  const handleModalClose = () => {
    setInputs(bulkUploadInputFixture);
    setOpenModal(false);
  };

  useEffect(() => {
    let optionData = {
      centerNames: centerNames,
      spsData: sps,
    };
    setFormFixture(bulkUploadFormFixture(inputs.update_type, optionData));
  }, [inputs.update_type, centerNames, sps]);

  useEffect(() => {
    const fetchOptions = async () => {
      let spsData = await getAllSPS();
      setSps(spsData);
    };
    fetchOptions();
  }, []);

  return (
    <Modal
      open={openModal && !loading}
      onClose={handleModalClose}
      header="Bulk Update Details"
    >
      <Form
        fixture={formFixture}
        inputs={inputs}
        loading={loading}
        onChange={handleInputChange}
        onSubmit={handleSubmit}
        handleBack={handleModalClose}
        showBackBtn={true}
        backBtnText="Close"
        backBtnClasses="btn-primary-border"
        nextBtnText="Save"
        BtnParentClasses="justify-content-end mt-3"
      />
    </Modal>
  );
};

export default BulkUpload;
